import React from "react"
import {Fade} from "react-awesome-reveal";
import Box from "@mui/material/Box";
import './css/Cards.css'

const cardsContent = [
    {title: 'Traducción de Documentos', img: './img/cards/Portadas destacadas-43.png', desc: ''},
    {title: 'Transcripciones de Contenido Audiovisual', img: './img/cards/Portadas destacadas-42.png', desc: ''},
    {title: 'Traducciones médicas', img: './img/cards/Portadas destacadas-45.png', desc: ''}
]

function Cards() {
    return (
        <div className="cardsDiv">
            {cardsContent.map((card, index) => (
                <Fade direction={'right'} key={index}>
                    <Box className="cards">
                        <div className="card">
                            <img src={card.img} className="card__image" alt=""/>
                            <div className="card__overlay">
                                <div className="card__header">
                                    <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">
                                        <path/>
                                    </svg>
                                    <div className="card__header-text">
                                        <h3 className="card__title">{card.title}</h3>
                                    </div>
                                </div>
                                <p className="card__description">{card.desc}</p>
                            </div>
                        </div>
                    </Box>
                </Fade>
            ))}
        </div>
    );
}

export default Cards;
import React from "react";
import { Fade } from "react-awesome-reveal";
import {Link, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import './css/Initial.css'

function Initial() {
    return (
        <div className="bgImage">
            <Fade>
                <Typography variant={"h1"} sx={{fontFamily: 'Aurora', fontSize: '60px', color:'#464938'}} >
                    Victoria Pascuet
                </Typography>
                <Typography variant={"h6"} sx={{fontFamily: 'Aurora', color:'#464938'}} >
                    Traducciones y soluciones lingüísticas
                </Typography>
                <div className={'initTypography'}>
                    <Typography
                        className={'initTypography'}
                        variant={"p"}
                        sx={{
                            fontFamily: 'Montserrat Medium',
                            fontSize: '18px',
                            color:"#464938"
                        }}
                    >
                        Brindamos servicios de traducción y corrección de todo tipo de documentos de la más alta calidad, tanto
                        para clientes particulares, empresas y/o instituciones.
                    </Typography>
                </div>
                <Button
                    variant="outlined"
                    sx={{
                        borderRadius: '40px',
                        border: 'solid 1px',
                        borderColor: '#464938',
                        textDecoration:'none',
                        marginTop:'60px',
                        color:'#464938',
                        '&:hover': {
                            borderColor: '#464938'
                        }
                    }}
                >
                    <Typography sx={{
                        m: '8px',
                        mx: '40px',
                        textTransform:'none',
                        fontSize:'22px',
                        fontFamily:'Montserrat Light'
                    }} >
                        <Link
                            href="https://wa.me/+543644592233?text=Hola!,%20quiero%20conocer%20m%C3%A1s%20de%20tus%20servicios."
                            target={'_blank'}
                            underline="none"
                            color="inherit"
                        >
                            Contactame
                        </Link>
                    </Typography>
                </Button>
            </Fade>
        </div>
    );
}

export default Initial;
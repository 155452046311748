import React from 'react';
import {styled} from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Container from "@mui/material/Container";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Box from "@mui/material/Box";

const Accordions = [
    {title: '¿Puedo solicitar el servicio vía online?', desc: 'lorem ipsum dolum', hidDesc: 'Sí. Todos los servicios se brindan de forma remota desde cualquier parte del mundo.'},
    {title: '¿Cuánto es el plazo de entrega promedio?', desc: 'lorem ipsum dolum', hidDesc: 'El plazo de entrega dependerá de la densidad del encargo y en base a su urgencia se determinará su valor.'},
    {title: '¿Qué documentos se pueden traducir?', desc: 'lorem ipsum dolum', hidDesc: 'Se pueden traducir todo tipo de documentos: obras literarias, manuales técnicos, artículos de carácter científico, cartas de recomendación, contenido audiovisual, etc.'},
    {title: '¿Qué tipos de documentos se pueden corregir?', desc: 'lorem ipsum dolum', hidDesc: 'La corrección de documentos abarca documentación personal como, por ejemplo, cartas de presentación. Solo se brinda este servicio para todo tipo de obras de autoría propia.'}
]

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    borderBottom: `1px solid `,
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
        {...props}
    />
))(({theme}) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(254, 248, 248, 1)'
            : 'rgba(254, 248, 248, 1)',

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor: 'rgba(254, 248, 248, 1)'
}));


export default function Faq() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div id={'idFAQ'}>
            <Container>
                <Box sx={{height: '600px',margin: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <Typography variant={'h2'} sx={{fontFamily: 'Aurora', fontSize: '50px', color: '#464938', mb: '30px'}}>
                        Preguntas Frecuentes
                    </Typography>


                    {Accordions.map((accordion, index) => {
                        return (
                            <Accordion className={'AccordionItem'} expanded={expanded === `panel${index}`}
                                       onChange={handleChange(`panel${index}`)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls={`panel${index + 1}bh-content`}
                                    id={`panel${index + 1}bh-header`}
                                >
                                    <Typography sx={{
                                        width: '50%',
                                        flexShrink: 0,
                                        textAlign: 'start',
                                        fontFamily: 'Montserrat Medium',
                                        fontSize: '18px',
                                        color: "#464938"
                                    }}>
                                        <HelpOutlineIcon sx={{mx: '5px', verticalAlign: 'sub'}}/>
                                        {accordion.title}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{fontFamily: 'Montserrat Light', fontSize: '20px', color: "#464938"}}>
                                        {accordion.hidDesc}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </Box>
            </Container>
        </div>
    );
}
import React from 'react';
import Logo from "../assets/logos/logocream.svg";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailIcon from '@mui/icons-material/Mail';
import Button from "@mui/material/Button";
import {Link as LinkScroll} from 'react-scroll'
import './css/Footer.css'
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Link} from "@mui/material";

const footerPages = [
    {name: 'Inicio', href: 'idInicio'},
    {name: 'Sobre Mi', href: 'idAboutMe'},
    {name: 'Preguntas Frecuentes', href: 'idFAQ'}
];

const Footer = () => {
    return (
        <div className={'footerBg'}>
            <Box className={'footerParent'} sx={{ flexDirection: { xs: 'column', md:'row' } }} >
                <Box className={'footerLeftChild'} sx={{ alignItems: { xs: 'center', md:'flex-start' }, paddingLeft : { xs: '0px', md: '10vw'} }} >
                    <Typography>Contáctame</Typography>
                    <li><LocationOnIcon/> Córdoba, Argentina</li>
                    <li><WhatsAppIcon/> +54 364 4592233</li>
                    <li><MailIcon/> victoriapascuet@outlook.com</li>
                    <Button
                        variant="outlined"

                    >
                        <Link
                            href={'https://wa.me/+543644592233?text=Hola!,%20quiero%20conocer%20m%C3%A1s%20de%20tus%20servicios.'}
                            target={'_blank'}
                        >
                            Contáctame
                        </Link>
                    </Button>
                </Box>

                <Box className={'footerRightChild'} sx={{ alignItems: { xs: 'center', md:'flex-end' }, paddingRight: {xs: '0' , md: '10vw'}, paddingTop: { xs: '60px', md: '0px'  } }}>
                    <img src={Logo} alt="Victoria Pascuet" height="40px"/>
                    {footerPages.map((page, index) => {
                        return (
                            <LinkScroll to={page.href}
                                        smooth={true}
                                        className="FooterLinks"
                                        href={`#${page.href}`}>
                                {page.name}
                            </LinkScroll>
                        )
                    })}
                    <div className={'SocialLinks'}>
                        <Link
                            href="https://www.linkedin.com/in/victoria-pascuet-577045211"
                            target={'_blank'}
                        >
                            <LinkedInIcon/>
                        </Link>
                        <Link
                            href="https://www.instagram.com/translations.victoriapascuet/"
                            target={'_blank'}
                        >
                            <InstagramIcon/>
                        </Link>
                    </div>
                </Box>
            </Box>
        </div>

    );
};

export default Footer;
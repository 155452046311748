import React from "react"
import {Typography} from "@mui/material";
import './css/About.css'

function About() {
    return (
        <div className={'aboutme'} id={'idAboutMe'}>
            <Typography variant={"h1"} sx={{fontFamily: 'Aurora', fontSize: '50px', color: '#464938'}}>
                Sobre mí
            </Typography>
            <Typography variant={"p"} sx={{fontFamily: 'Montserrat Medium', fontSize: '18px', color: "#464938"}}>
                Mi nombre es Victoria Pascuet. Me especializo en la traducción de documentos de inglés a español y
                viceversa. Cuento con amplia experiencia en la traducción de material de carácter literario, médico,
                técnico-científico; así como también partidas de nacimiento, títulos universitarios y cartas de
                recomendación, entre otros.
            </Typography>
        </div>
    );
}

export default About;

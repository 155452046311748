import './App.css';
import AppBar from "./components/AppBar";
import Initial from "./components/Initial";
import Container from "@mui/material/Container";
import About from "./components/About";
import Cards from "./components/Cards";
import Faq from "./components/FAQ";
import Footer from "./components/Footer"

function App() {
  return (
    <div className="App">
        <AppBar />
            <Container disableGutters={true} maxWidth={'false'}>
                <Initial />
                <About />
                <Cards />
                <Faq />
                <Footer />
            </Container>
    </div>
  );
}

export default App;

import React from "react";
import AppBar from '@mui/material/AppBar';
import { useState } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import './css/AppBar.css'
import '../fonts/Aurora.otf'
import '../fonts/Montserrat-Light.otf'
import Logo from '../assets/logos/logocream.svg'
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link, animateScroll as scroll } from 'react-scroll'
import { Link as LinkATag } from '@mui/material'

const pages = [{name: 'Inicio', href: 'idInicio'}, {name: 'Sobre Mi', href: 'idAboutMe'}, {name: 'Preguntas Frecuentes', href: 'idFAQ'}];


const ResponsiveAppBar = () => {
    const [anchorElNav, setAnchorElNav] = useState(null)

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar id={'idInicio'} elevation={0} sx={{ bgcolor: "#464938" }} position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'Aurora',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src={Logo} alt="Victoria Pascuet" height="70px" style={{ marginTop: "20px", marginBottom: "20px" }}/>
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center" fontFamily="Montserrat">
                                        <Link
                                        to={page.href}
                                        smooth={true}
                                        className="CollapsedLinks"
                                        href={`#${page.href}`}
                                        >{page.name}</Link>    
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'Aurora',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Victoria Pascuet
                    </Typography>
                    <Box className={'AppBarLinks'} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: "flex-end" }}>
                        {pages.map((page) => (
                            <Button
                                key={page.name}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, mx: 2, color: '#D0C9BF', display: 'block', fontFamily: 'Montserrat', fontWeight: '500' }}
                            >
                                <Link
                                    to={page.href}
                                    smooth={true}
                                    className="NavBarLinks"
                                    href={`#${page.href}`}
                                >{page.name}</Link>
                            </Button>
                        ))}
                        <div className={'SocialLinks'}>
                            <LinkATag
                                href="https://www.linkedin.com/in/victoria-pascuet-577045211"
                                target={'_blank'}
                            >
                                <LinkedInIcon/>
                            </LinkATag>
                            <LinkATag
                                href="https://www.instagram.com/translations.victoriapascuet/"
                                target={'_blank'}
                            >
                                <InstagramIcon/>
                            </LinkATag>
                        </div>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;